<template>
  <div>
    <div>
      {{'order.action.invalid.help'| t}}
    </div>

    <v-checkbox
      v-if="accountingModule"
      dense
      class="mt-4"
      :label="$t('order.action.invalid.add_revenue')"
      v-model="options.addRevenue"
    ></v-checkbox>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    options: {
      addRevenue: false,
    },
  }),
  computed: {
    accountingModule() {
      return this.feature.accountingModule === true
    },
    feature() {
      return this.$store.getters['base/feature']
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.options)
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
